import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Strong, LinkBox, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Корти Альтаїр
			</title>
			<meta name={"description"} content={"Покращуємо кожну подачу, вдосконалюємо кожну гру"} />
			<meta property={"og:title"} content={"Наші послуги | Корти Альтаїр"} />
			<meta property={"og:description"} content={"Покращуємо кожну подачу, вдосконалюємо кожну гру"} />
			<meta property={"og:image"} content={"https://clarivistaboom.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://clarivistaboom.com/img/5501904.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://clarivistaboom.com/img/5501904.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://clarivistaboom.com/img/5501904.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://clarivistaboom.com/img/5501904.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://clarivistaboom.com/img/5501904.png"} />
			<meta name={"msapplication-TileImage"} content={"https://clarivistaboom.com/img/5501904.png"} />
			
		</Helmet>
		<Components.Header />
		<Section
			padding="80px 0 80px 0"
			lg-padding="60px 0 60px 0"
			md-padding="40px 0 40px 0"
			sm-padding="30px 0 30px 0"
			background="--color-darkL2"
		>
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--light"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Наші послуги
				</Text>
				<Text font="--lead" color="--light" max-width="900px">
				На кортах Альтаїр ми виходимо за рамки простого надання кортів - ми створюємо середовище, яке сприяє кожному аспекту вашої тенісної гри. Від найсучаснішого обладнання до індивідуальних тренувань - наші послуги призначені для гравців усіх рівнів, які прагнуть покращити свою гру у всебічно сприятливому середовищі. Відчуйте різницю, скориставшись нашими тенісними пропозиціями найвищого рівня.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://clarivistaboom.com/img/8.jpg"
						border-radius="24px"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						display="block"
						position="static"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
						Бронювання кортів
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
							<Strong>
							Удосконалена система бронювання:  {" "}
							</Strong>
							{" "}Легко забронюйте корт заздалегідь за допомогою нашої спрощеної системи бронювання, щоб завжди мати місце для гри.
							<br />
							<Strong>
							Різноманітність покриттів:  {" "}
							</Strong>
							Вибирайте з глиняних, твердих або трав'яних кортів, кожен з яких відповідає професійним стандартам для оптимальної гри.
							<br />
							<Strong>
								Освітлення для нічних матчів: {" "}
							</Strong>
							 Наші корти обладнані високоякісним освітленням, що робить вечірні або нічні сесії такими ж приємними, як і денні.

						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
						Тренування та школа
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
							<Strong>
							Індивідуальні тренування: 
							</Strong>
							{" "}індивідуальні заняття з сертифікованими тенісними тренерами, які будуть працювати з вашим стилем і цілями.
							<br />
							<br />
							<Strong>
							Групові тренування: 
							</Strong>
							{" "}Приєднуйтесь до однолітків з аналогічним рівнем майстерності в групових тренуваннях, призначених для покращення певних аспектів вашої гри.
							<br />
							<br />
							<Strong>
							Юніорські програми: 
							</Strong>
							{" "}Спеціалізовані програми для юних гравців, що фокусуються на фундаментальних основах, спортивній майстерності та змагальній грі.

						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://clarivistaboom.com/img/9.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://clarivistaboom.com/img/10.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
						Екіпірування та спорядження
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
							<Strong>
							Pro Shop: 
							</Strong>
							{" "}В наявності найновіше тенісне спорядження, від ракеток і взуття до одягу та аксесуарів.
							<br />
							<br />
							<Strong>
							Послуги з набивання струн для ракеток: 
							</Strong>
							{" "}Експертні послуги з нарізки струн для підтримки вашої ракетки в ідеальному стані.
							<br />
							<br />
							<Strong>
							Демонстраційні ракетки: 
							</Strong>
							{" "}Спробуйте, перш ніж купувати, з нашою програмою демонстраційних ракеток, щоб переконатися, що ви знайдете ракетку, яка ідеально підходить для вашого стилю гри.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
						Зв'яжіться з нами сьогодні, щоб запланувати свій візит або дізнатися більше про наші комплексні тенісні програми та послуги. На кортах Альтаїр тенісні корти ми не просто граємо в гру - ми її вдосконалюємо. Приєднуйтесь до нас, щоб дізнатися, як ми можемо допомогти вам розкрити свій потенціал на корті.
						</Text>
						<Box
							min-width="100px"
							min-height="100px"
							margin="20px 0px 0px 0px"
							text-align="center"
							padding="20px 0px 0px 0px"
						>
							<Link
								href="/contacts"
								color="#000000"
								padding="8px 24px 8px 024px"
								background="--color-orange"
								text-decoration-line="initial"
								font="--lead"
								margin="0px 0px 0px 0px"
								border-radius="8px"
							>
								Звʼязатись з нами
							</Link>
						</Box>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://clarivistaboom.com/img/11.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fe240cd81ee001f7dd8c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});